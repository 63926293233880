<!--
 * @Author: fangting fangting@whty.com.cn
 * @Date: 2022-12-13 10:05:59
 * @LastEditors: fangting fangting@whty.com.cn
 * @LastEditTime: 2022-12-13 13:15:28
 * @FilePath: \web\src\views\enterprise\modules\dialog\checkKey.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
    <Dialog ref="dialog" :config="config" :visible='visible' :cancelDialog="cancelDialog" :confirmDialog='submitGroupForm' :isdeling='isdeling' append>
        <div class="contentBox">
          禁用后企业将无法开票,请确认是否禁用该企业?
        </div>
       
  </Dialog>
</template>

<script>
import Dialog from "@/components/Dialog/index";
import { regionData ,CodeToText } from 'element-china-area-data'
export default {
  components: {
    Dialog,
  },
  props: {
    type:{
      type: String,
    },
    rowData :{
      type: Object,
    },
    visible: {
      type: Boolean,
    },
    isdeling: {
      type: Boolean,
    },
    cancelDialog:{
      type: Function,
      default: () => {},
    },
    confirmDialog:{
      type: Function,
      default: () => {},
    }
  },
  
  data() {
   return {
      config: {
        top: '14vh',
        width: '22rem',
        title: '提示',
        center: true,
        btnTxt: ['确定','取消'],
      },
      ruleForm: {
          appId:'',
          privateKey: '',
          publicKey:'',
        },
      // rules: {
      //     year: [
      //       { required: true, message: '请输入续费年限', trigger: 'blur' },
      //     ],
      //   }
    };
  },
  methods: {
      submitGroupForm(){
         this.confirmDialog() 
       }
  }
};
</script>

<style lang="less" scoped>
  .groupForm{
    width: 100%;
    margin: 0 auto;
    .submitGroupBtn{
      margin: 20px auto 0;
      display: block;
    }
  }
  /deep/ .el-form--label-top .el-form-item__label{
    padding: 0;
  }
  /deep/ .el-form-item {
    margin-bottom: 0.7rem;
  }
  .dialogClass{
    margin-left: 200px;
    margin-bottom: 20px;
  }
  .contentBox{
    text-align: center;
    padding: 10px 0 10px 0;
  }
</style>
